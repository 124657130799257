import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ProductIcon = styled.img`
  width: 35.9px;
  height: 35.9px;
  margin-right: 18px;
  background-color: #fff;
  margin-top: 4px;
  margin-left: 22px;
`;

const DropDownProductTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #484e62;
`;

const DropDownProductSubtitle = styled.span`
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: 0.08px;
  text-align: left;
  color: #68878d;
  display: flex;
`;

const DropDownFreeVersionTag = styled.span`
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  color: #00aed4;
  padding: 1px 4px 2px;
  margin-left: 8px;
  border-radius: 3px;
  background-color: #ebfafe;
`;

const TextContainer = styled.div`
  margin-top: 4px;
  display: block;
`;

const LinkContainer = styled.div`
  display: flex;
`;

const DropDownItem = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  padding-top: 8px;
  height: 58px;
  border-radius: 5px;
  background-color: #fff;
  &:hover {
    background-color: #f7f9f9;
    cursor: pointer;
  }
`;

function AppLink({
  link,
  icon,
  productName,
  product,
  isActive,
  freeLabel,
  description,
  currentApp,
}) {
  const isDisabled = product === currentApp;
  
  return (
    <DropDownItem href={isDisabled ? undefined : link} target="_blank" disabled={isDisabled}>
      <LinkContainer>
        <ProductIcon src={icon} />
        <TextContainer>
          <DropDownProductTitle>
            {productName} {currentApp}
            {isActive ? null : (
              <DropDownFreeVersionTag>{freeLabel}</DropDownFreeVersionTag>
            )}
          </DropDownProductTitle>
          <DropDownProductSubtitle>{description}</DropDownProductSubtitle>
        </TextContainer>
      </LinkContainer>
    </DropDownItem>
  );
}

AppLink.defaultProps = {
  link: null,
  icon: null,
  productName: null,
  isActive: true,
  freeLabel: null,
  description: null,
};

AppLink.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  productName: PropTypes.string,
  isActive: PropTypes.bool,
  freeLabel: PropTypes.string,
  description: PropTypes.string,
};

export default AppLink;
