import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from 'react-select';
import { theme as Rtheme } from '../Utils/theme';
import { ErrorUI } from './ErrorUI';

const Wrapper = styled.div`
  margin-top: ${p => p.mt ? p.mt : '0px'};
  margin-bottom: 5px;
  width: ${p => p.width};
  display: inline-block;
`;

const singleValueExtended = (props) => ({
  lineHeight: '23px',
  color: `${props.optionColor || Rtheme.colors.primary}`,
});

const optionsValueExtended = () => ({
  color: Rtheme.colors.fontColor,
  fontSize: '15px',
  cursor: 'pointer',
  borderRadius: '4px',
  margin: '3px 0px',
  ':hover': {
    backgroundColor: Rtheme.colors.bgActive,
  },
});

const controlValueExtended = (
  { border, borderOnFocus, borderRadius, removeRightBorder, height, width },
  { isFocused },
) => {
  let defaultBorder = `solid 1px ${Rtheme.colors.borderColor} !important`;

  if (borderOnFocus) {
    defaultBorder = !isFocused
      ? `solid 1px ${Rtheme.colors.shadow} !important`
      : `solid 1px ${Rtheme.colors.borderColorFocus}`;
  }

  return {
    borderRadius,
    borderRightColor: removeRightBorder && 'transparent',
    borderTopRight: removeRightBorder ? '0px' : undefined,
    height,
    width,
    backgroundColor: 'white',
    border: border || defaultBorder,
    boxShadow: 'none',
    fontSize: '15px',
    cursor: 'pointer',
  };
};

const placeholderValueExtended = () => {
  return {
    color: Rtheme.colors.ghost,
  };
};

const colourStyles = props => {
  return {
    singleValue: styles => ({ ...styles, ...singleValueExtended(props) }),
    option: styles => ({ ...styles, ...optionsValueExtended() }),
    control: (styles, state) => ({
      ...styles,
      ...controlValueExtended(props, state),
    }),
    placeholder: (styles, state) => ({
      ...styles,
      ...placeholderValueExtended(props, state),
    }),
    menu: styles => ({ ...styles, marginTop: '3px' }),
    menuList: styles => ({ ...styles, padding: '5px' }),
    multiValue: styles => ({
      ...styles,
      color: Rtheme.colors.primary,
      lineHeight: '23px',
      backgroundColor: Rtheme.colors.bgAlternative,
    }),
    multiValueLabel: styles => ({
      ...styles,
      color: Rtheme.colors.primary,
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: Rtheme.colors.primary,
      ':hover': {
        backgroundColor: Rtheme.colors.primary,
        color: Rtheme.colors.bgAlternative,
      },
    }),
  };
};

export const InputSelect = ({ meta, ...props }) => {
  const { onChange, value } = props.input;
  return (
    <>
      <Wrapper width={props.width} mt={props.mt}>
        <Select
          onChange={onChange}
          theme={theme => {
            return {
              ...theme,
              colors: {
                ...theme.colors,
                primary: Rtheme.colors.white,
                primary25: Rtheme.colors.bgActive,
                neutral20: Rtheme.colors.border,
                neutral80: Rtheme.colors.fontColor,
              },
              spacing: {
                baseUnit: 5,
              },
            };
          }}
          styles={colourStyles(props)}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          {...props}
        />
      </Wrapper>
      <ErrorUI meta={meta} />
    </>
  );
};

InputSelect.defaultProps = {
  borderRadius: '5px',
  height: '50px',
  width: '100%',
  mt: '0px',
  borderOnFocus: true,
  meta: { error: undefined, touched: undefined },
  input: {
    onChange: () => {},
  },
};

InputSelect.propTypes = {
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  mt: PropTypes.string,
  borderOnFocus: PropTypes.bool,
  meta: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  input: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
